<template>
  <div class="table-container">
    <div class="table-header">
      {{
        `REPORTE DE LOGROS DE ${String(
          this.$getVisibleNames("mesh.competence", true, "Competencias")
        ).toUpperCase()} POR ESTUDIANTE`
      }}
    </div>
    <b-table
      :items="items"
      :fields="
        [{ key: 'section', label: this.column_name, sortable: true }].concat(
          fields
        )
      "
      small
      responsive
      thead-class="custom-header small"
      table-class="simple-report-table small"
      bordered
    >
      <template v-slot:head()="data">
        <div
          v-if="data.field.label.includes('Competencia')"
          v-b-tooltip.v-secondary.noninteractive="
            competenceTooltip(data.field.label)
          "
        >
          {{
            $getVisibleNames(
              "mesh.competence",
              false,
              "Competencia"
            ).toUpperCase()
          }}{{ data.field.label.substring(11) }}
        </div>
        <div v-else-if="data.field.label == 'PERFIL DE EGRESO'">
          {{
            $getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil De Egreso"
            ).toUpperCase()
          }}
        </div>
        <div
          v-else
          class="custom-table-header"
          :class="{
            'red-background':
              data.field.label == 'RANGO INSUFICIENTE' ||
              data.field.label == 'INSUFICIENTE',
            'yellow-background':
              data.field.label == 'RANGO SUFICIENTE' ||
              data.field.label == 'SUFICIENTES',
            'green-background':
              data.field.label == 'RANGO DESTACADO' ||
              data.field.label == 'DESTACADOS',
          }"
        >
          {{ data.field.label }}
        </div>
      </template>
      <template v-slot:cell()="data">
        <template>
          <template>
            <template
              v-if="
                Array.isArray(data.value) && data.field.label == 'ASIGNATURAS'
              "
            >
              <div
                :id="`matters-cell-${data.item.section}`"
                class="matters-cell"
              >
                {{ data.value[0] }}
              </div>
              <b-popover
                :target="`matters-cell-${data.item.section}`"
                triggers="hover"
              >
                <template #title>{{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                }}</template>
                <template v-for="(matters, index) in data.value">
                  <div :key="matters.id" v-if="index > 0">
                    <div v-for="matter in matters" :key="matter.id">
                      <b-icon icon="circle-fill" scale="0.2"></b-icon
                      >{{ matter }}
                    </div>
                  </div>
                </template>
              </b-popover>
            </template>
            <div v-else>
              <div
                v-if="
                  chartdata.extra_data &&
                  data.field.label != 'N°' &&
                  data.field.label != 'ESTUDIANTE' &&
                  data.field.label != 'AÑO DE INGRESO' &&
                  data.field.label != 'PERFIL DE EGRESO'
                "
                :class="{
                  'bg-red':
                    data.value <=
                    chartdata.extra_data.achievement_ranges[0][2] + '%',
                  'bg-yellow':
                    data.value >=
                      chartdata.extra_data.achievement_ranges[1][1] + '%' &&
                    data.value <=
                      chartdata.extra_data.achievement_ranges[1][2] + '%',
                  'bg-green':
                    data.value >=
                      chartdata.extra_data.achievement_ranges[2][1] + '%' ||
                    data.value ==
                      chartdata.extra_data.achievement_ranges[2][2] + '%',
                  'bg-transparent': data.value == '-',
                }"
              >
                {{ data.value }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </div>
          </template>
        </template>
      </template>
    </b-table>
    <template v-if="!chartdata.datasets.length">
      <div class="empty-dataset-alert">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon> No hay datos
        para mostrar.
      </div>
    </template>
    <template>
      <div class="report-ranges-caption">
        <div class="report-ranges-header">RANGOS DE LOGROS</div>
        <template
          v-for="(grade_range, index) in chartdata.extra_data
            .achievement_ranges"
        >
          <div class="report-range" :key="grade_range.id">
            <span class="range-label">
              {{ chartdata.extra_data.achievement_ranges[index][0] }}
            </span>
            <div
              :class="{
                'range-value-red': index == 0,
                'range-value-yellow': index == 1,
                'range-value-green': index == 2,
              }"
            >
              {{ chartdata.extra_data.achievement_ranges[index][1] + "%" }} -
              {{ chartdata.extra_data.achievement_ranges[index][2] + "%" }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template>
      <download-excel
        class="btn btn-primary mt-2"
        :data="itemsXLS"
        :fields="jsonFields"
        :worksheet="`${String(
          this.$getVisibleNames('mesh.competence', true, 'Competencias')
        ).toUpperCase()} POR ESTUDIANTE`"
        :name="`Reporte de Logros de ${String(
          this.$getVisibleNames('mesh.competence', true, 'Competencias')
        )} por Estudiante.xls`"
        :header="headerArray"
        :footer="footerArray"
      >
        Descargar XLS
      </download-excel>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "StudentCompetenceAchievement",
  props: ["chartdata", "selectors"],
  data() {
    return {
      section_name: "",
      label_tooltip: "",
      column_name: "N°",
    };
  },
  computed: {
    ...mapGetters({}),
    items() {
      let list = [];
      for (let a = 0; a < this.chartdata.datasets.length; a++) {
        let row = { section: this.chartdata.datasets[a].label };
        for (
          let index = 0;
          index < this.chartdata.datasets[a].data.length;
          index++
        ) {
          let label = this.chartdata.labels[index];
          const data = this.chartdata.datasets[a].data[index];
          if (
            (!isNaN(data) && index > 3 && data != null) ||
            (label.includes("Competencia") && data != null)
          ) {
            row[label] = data + "%";
          } else if (data == null) {
            row[label] = "-";
          } else {
            row[label] = data;
          }
        }
        list.push(row);
      }
      return list;
    },
    itemsXLS() {
      let list = [];
      for (let a = 0; a < this.chartdata.datasets.length; a++) {
        let row = { section: this.chartdata.datasets[a].label };
        for (
          let index = 0;
          index < this.chartdata.datasets[a].data.length;
          index++
        ) {
          let label = this.chartdata.labels[index];
          if (this.chartdata && this.chartdata.datasets.length > 0) {
            const data = this.chartdata.datasets[a].data[index];
            if (!isNaN(data) && data != null && label.includes("Competencia")) {
              row[label] = data + "%";
            } else if (data == null) {
              row[label] = "";
            } else {
              row[label] = data;
            }
          }
        }
        list.push(row);
      }
      return list;
    },
    fields() {
      let list = [];
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        const column_header = this.chartdata.labels[index];
        list.push(column_header);
      }
      return list;
    },
    footers() {
      let list = [];
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        let counter = 0;
        this.items.forEach((section) => {
          counter += section[this.chartdata.labels[index]];
        });
        list.push(counter);
      }
      return list;
    },
    jsonFields() {
      let json_fields = { "N°": "section" };
      this.fields.forEach((label) => {
        json_fields[label] = label;
      });
      return json_fields;
    },
    jsonFooter() {
      let json_footer = { section: "Totales" };
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        const label = this.chartdata.labels[index];
        const data = this.footers[index];
        json_footer[label] = data;
      }
      return json_footer;
    },
  },
  methods: {
    headerArray() {
      let list = "";
      list +=
        "<tr>" +
        `<th colspan='${
          this.chartdata.labels.length + 4
        }' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
        `REPORTE DE LOGROS DE ${String(
          this.$getVisibleNames("mesh.competence", true, "Competencias")
        ).toUpperCase()} POR ESTUDIANTE` +
        "</th>" +
        "</tr>";
      if (this.selectors.selected_campuses.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "CAMPUS: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_campuses[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_egress_profiles) {
        list +=
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          ).toUpperCase() +
          ":" +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_egress_profiles[0].professional_title.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_periods) {
        list +=
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames("mesh.period", false, "Período").toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_periods[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_levels) {
        list +=
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "NIVEL: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          this.selectors.selected_levels[0].name +
          "</b>" +
          "</td>";
      }
      return list;
    },
    footerArray() {
      let list = "";
      if (this.chartdata.extra_data) {
        let ranges = this.chartdata.extra_data.achievement_ranges;
        list +=
          "<tr>" +
          `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
          "RANGOS DE NOTAS" +
          "</th>" +
          "</tr>" +
          "<tbody>" +
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[0][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          ranges[0][1] +
          "%" +
          " - " +
          ranges[0][2] +
          "%" +
          "</b>" +
          "</td>" +
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[1][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          ranges[1][1] +
          "%" +
          " - " +
          ranges[1][2] +
          "%" +
          "</b>" +
          "</td>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[2][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          ranges[2][1] +
          "%" +
          " - " +
          ranges[2][2] +
          "%" +
          "</b>" +
          "</td>" +
          "</tr>" +
          "</tbody>";

        if (this.chartdata.extra_data.competences) {
          list +=
            "<tr>" +
            `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
            `${String(
              this.$getVisibleNames("mesh.competence", true, "Competencias")
            ).toUpperCase()}` +
            "</th>" +
            "</tr>";

          for (
            let index = 0;
            index < this.chartdata.extra_data.competences.length;
            index++
          ) {
            let competence = this.chartdata.extra_data.competences[index];
            if (this.chartdata.extra_data.competences[index][1] != null) {
              list +=
                "<tr>" +
                `<th colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black' >` +
                competence[2].toUpperCase() +
                "</th>" +
                `<th colspan='6' style='vertical-align: middle; border: .5pt solid black; text-align: justify !important'>` +
                competence[0] +
                "." +
                competence[1] +
                ". " +
                competence[3].toUpperCase() +
                "</th>" +
                "</tr>";
            } else {
              list +=
                "<tr>" +
                `<th colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black' >` +
                competence[2].toUpperCase() +
                "</th>" +
                `<th colspan='6' style='vertical-align: middle; border: .5pt solid black; text-align: justify !important'>` +
                competence[0] +
                ". " +
                competence[3].toUpperCase() +
                "</th>" +
                "</tr>";
            }
          }
        }
      }
      return list;
    },
    headerTooltip(data_label) {
      let tooltip_content = "";
      this.chartdata.labels.forEach((label) => {
        if (label[0] == data_label) {
          tooltip_content = label[1];
        }
      });
      return tooltip_content;
    },
    competenceTooltip(statement) {
      let tooltip_statement = "";
      this.chartdata.extra_data.competences.forEach((x) => {
        if (statement == x[2]) {
          tooltip_statement = x[0];
          if (x[1] != null) {
            tooltip_statement = tooltip_statement + "." + x[1] + ". " + x[3];
          } else {
            tooltip_statement = tooltip_statement + ". " + x[3];
          }
        }
      });
      return tooltip_statement;
    },
  },
  mounted() {
    if (this.section) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section)
        .then((response) => {
          this.section_name = response.name;
        });
    } else {
      this.section_name = "SIN SELECCIONAR";
    }
  },
};
</script>

<style scoped>
.table-container {
  scroll-behavior: auto;
}
.table-thead {
  font-size: 5pt;
}
.table-header {
  background: var(--primary-color);
  font-weight: bold;
  padding: 0.2em;
}
.table-caption {
  display: flex;
}
.custom-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: var(--primary-hover-color);
  width: 100%;
  height: 2.4em;
  top: 10px;
}
.red-background {
  background: #f19292 !important;
}
.yellow-background {
  background: #fff1ac !important;
}
.green-background {
  background: #bbeaa6 !important;
}
.bg-red,
.bg-yellow,
.bg-green {
  height: 100% !important;
  vertical-align: middle;
  bottom: 50% !important;
  padding-top: 5px;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.table-responsive >>> th {
  padding: 0 !important;
}
.table-responsive.report-secondary >>> td:nth-of-type(1) {
  width: 45%;
}
.table-responsive.report-secondary >>> td:nth-of-type(2) {
  width: auto !important;
}
.competence-cell {
  display: flex;
}
.student-header,
.achievement-header {
  background: var(--primary-hover-color);
  overflow-x: hidden;
}
.student-header {
  min-width: 40%;
}
.achievement-header {
  width: 70%;
}
.table-foot {
  background-color: darkgrey;
}
.table-responsive >>> .table {
  text-align: center !important;
}
.table-container >>> .custom-header {
  background: var(--primary-hover-color) !important;
}
.table-container >>> .table thead th {
  vertical-align: middle !important;
}
.amount-caption,
.percent-caption {
  width: 50%;
  background: var(--primary-hover-color);
  border: 1px solid #dee2e6;
  font-size: 80%;
  font-weight: bold;
}
.amount-value,
.percent-value {
  width: 50%;
  margin-top: 0.4em;
}
.table-section {
  display: flex;
}
.table-total {
  border: 1px solid #dee2e6;
  margin-bottom: 1rem;
  display: table-row-group;
  background-color: rgba(0, 0, 0, 0.05);
}
.table-total-caption {
  background: var(--primary-hover-color);
  color: #212529;
  border-bottom: 2px solid #dee2e6;
  border-bottom-width: 2px;
  vertical-align: middle !important;
  padding: 0.3rem;
  font-size: 65% !important;
  font-weight: bold;
  margin: 0;
  width: max-content;
  text-indent: initial;
  border-spacing: 2px;
}
.first-column-container {
  margin-top: 0.6em;
}
.total-amount {
  line-height: 3.36em;
  font-size: 95%;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
  background: #fff;
}
.sentence-component-amount {
  line-height: 4.82em;
  font-size: 95%;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
  background: #fff;
}
.total-amount:last-of-type {
  border-bottom: none;
}
.report-ranges-caption {
  width: 100%;
  border: 1px solid var(--primary-hover-color);
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--primary-hover-color);
  color: var(--secondary-font-color);
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
}
.range-label {
  text-align: left;
  width: 15%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 15%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 15%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 15%;
}
.sections-cell,
.matters-cell {
  text-decoration: underline;
}
.section-matter {
  display: block;
  font-weight: bold;
  line-height: 12pt;
}
.sections-container {
  overflow: auto;
}
.empty-dataset-alert {
  margin: 2em 0;
  font-size: 13pt;
}
</style>