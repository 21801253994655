var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(`REPORTE DE LOGROS DE ${String( this.$getVisibleNames("mesh.competence", true, "Competencias") ).toUpperCase()} POR ESTUDIANTE`)+" ")]),_c('b-table',{attrs:{"items":_vm.items,"fields":[{ key: 'section', label: this.column_name, sortable: true }].concat(
        _vm.fields
      ),"small":"","responsive":"","thead-class":"custom-header small","table-class":"simple-report-table small","bordered":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [(data.field.label.includes('Competencia'))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          _vm.competenceTooltip(data.field.label)
        ),expression:"\n          competenceTooltip(data.field.label)\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.competence", false, "Competencia" ).toUpperCase())+_vm._s(data.field.label.substring(11))+" ")]):(data.field.label == 'PERFIL DE EGRESO')?_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil De Egreso" ).toUpperCase())+" ")]):_c('div',{staticClass:"custom-table-header",class:{
          'red-background':
            data.field.label == 'RANGO INSUFICIENTE' ||
            data.field.label == 'INSUFICIENTE',
          'yellow-background':
            data.field.label == 'RANGO SUFICIENTE' ||
            data.field.label == 'SUFICIENTES',
          'green-background':
            data.field.label == 'RANGO DESTACADO' ||
            data.field.label == 'DESTACADOS',
        }},[_vm._v(" "+_vm._s(data.field.label)+" ")])]}},{key:"cell()",fn:function(data){return [[[(
              Array.isArray(data.value) && data.field.label == 'ASIGNATURAS'
            )?[_c('div',{staticClass:"matters-cell",attrs:{"id":`matters-cell-${data.item.section}`}},[_vm._v(" "+_vm._s(data.value[0])+" ")]),_c('b-popover',{attrs:{"target":`matters-cell-${data.item.section}`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" )))]},proxy:true}],null,true)},[_vm._l((data.value),function(matters,index){return [(index > 0)?_c('div',{key:matters.id},_vm._l((matters),function(matter){return _c('div',{key:matter.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.2"}}),_vm._v(_vm._s(matter)+" ")],1)}),0):_vm._e()]})],2)]:_c('div',[(
                _vm.chartdata.extra_data &&
                data.field.label != 'N°' &&
                data.field.label != 'ESTUDIANTE' &&
                data.field.label != 'AÑO DE INGRESO' &&
                data.field.label != 'PERFIL DE EGRESO'
              )?_c('div',{class:{
                'bg-red':
                  data.value <=
                  _vm.chartdata.extra_data.achievement_ranges[0][2] + '%',
                'bg-yellow':
                  data.value >=
                    _vm.chartdata.extra_data.achievement_ranges[1][1] + '%' &&
                  data.value <=
                    _vm.chartdata.extra_data.achievement_ranges[1][2] + '%',
                'bg-green':
                  data.value >=
                    _vm.chartdata.extra_data.achievement_ranges[2][1] + '%' ||
                  data.value ==
                    _vm.chartdata.extra_data.achievement_ranges[2][2] + '%',
                'bg-transparent': data.value == '-',
              }},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])])]]]}}])}),(!_vm.chartdata.datasets.length)?[_c('div',{staticClass:"empty-dataset-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No hay datos para mostrar. ")],1)]:_vm._e(),[_c('div',{staticClass:"report-ranges-caption"},[_c('div',{staticClass:"report-ranges-header"},[_vm._v("RANGOS DE LOGROS")]),_vm._l((_vm.chartdata.extra_data
          .achievement_ranges),function(grade_range,index){return [_c('div',{key:grade_range.id,staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][0])+" ")]),_c('div',{class:{
              'range-value-red': index == 0,
              'range-value-yellow': index == 1,
              'range-value-green': index == 2,
            }},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][1] + "%")+" - "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][2] + "%")+" ")])])]})],2)],[_c('download-excel',{staticClass:"btn btn-primary mt-2",attrs:{"data":_vm.itemsXLS,"fields":_vm.jsonFields,"worksheet":`${String(
        this.$getVisibleNames('mesh.competence', true, 'Competencias')
      ).toUpperCase()} POR ESTUDIANTE`,"name":`Reporte de Logros de ${String(
        this.$getVisibleNames('mesh.competence', true, 'Competencias')
      )} por Estudiante.xls`,"header":_vm.headerArray,"footer":_vm.footerArray}},[_vm._v(" Descargar XLS ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }